/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton,
  ActionButtonSecondary,
  Spinner
} from "screens/commons";

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isArnDetected: PropTypes.bool.isRequired,
  partnerDetails: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  redirectingUser: PropTypes.bool.isRequired,
  fetchingArn: PropTypes.bool.isRequired,
  onSkipArn: PropTypes.bool.isRequired,
  onUploadArn: PropTypes.bool.isRequired
};

const CheckArn = ({
  onSubmit,
  isArnDetected,
  partnerDetails,
  isLoading,
  fetchingArn,
  redirectingUser,
  onSkipArn,
  onUploadArn
}) => {
  const arnDetected = "https://i.wlycdn.com/wealthy-kyc/arn-detected.svg";
  const arnFailed = "https://i.wlycdn.com/wealthy-kyc/arn-failed.svg";
  const showLoader = isLoading || redirectingUser || fetchingArn;

  return (
    showLoader ? (
      <LoaderContainer>
        <Spinner
          color="purple"
          message={fetchingArn ? "Please be patient, The search process may take up to 1 minute" : "Updating your information, Please do not close the window"}
        />
      </LoaderContainer>
    ) : (
      <CheckArnWrapper>
      <SubHeading>Manual KYC</SubHeading>
        <TitleWrapper >
        <Title>ARN not detected</Title>
        {/* <SubTitleWrapper>
          <SubTitle>Step 1/3</SubTitle>
        </SubTitleWrapper> */}
      </TitleWrapper>
        <Container>
          {/* <HeaderText>Skip</HeaderText> */}
          <ArnContentWrapper>
            <ArnImage>
              <img
                alt="Arn Detected"
                className="arn-detected"
                src={isArnDetected ? arnDetected : arnFailed}
              />
            </ArnImage>
            {
              isArnDetected ? (
                <>
                  <Title>ARN Detected !!</Title>
                  <Description>
                    {partnerDetails.arnNo} ARN <SubHeader>was detected for PAN <br />{partnerDetails.panNumber} </SubHeader>
                  </Description>
                </>
              ) : (
                <>
                  <Title>ARN Not Detected !!</Title>
                  <Description>
                    <SubHeader>
                      We couldn’t find any ARN associated with your<br/> PAN <strong>{partnerDetails.panNumber}</strong>.
                    </SubHeader>
                  </Description>
                </>
              )
            }
          </ArnContentWrapper>
          <ArnFooter>
            {
              isArnDetected ?
                (
                  <>
                    <SubHeader>To accept ARN, Please sign the agreement
                      by checking the agreement</SubHeader>
                    <ActionButton
                        buttonText="Continue"
                      callback={onSubmit}
                      // additionalStyles={{marginBottom: '30%'}}
                    />
                  </>
                ) : (
                  <>
                    <InfoContainer>
                      <ImageContainer>
                        {/* <img src="https://i.wlycdn.com/client-app/Arn-Card-Illustration.png" alt="arn-card"/> */}
                        <img src="https://wealthy-media-test.s3.amazonaws.com/wealthy-kyc/arn-upload.svg" alt="arn-card"/>
                      </ImageContainer>
                      <ImageSubText>
                      You can upload an image of your ARN card similar to the illustration shown here.
                      </ImageSubText>
                    <ActionButton
                      buttonText="Upload ARN Card Image"
                      callback={onUploadArn}
                      additionalStyles={{padding: '1.5rem'}}
                    />
                    </InfoContainer>

                    {/* <ActionButtonSecondary
                      buttonText="Skip ARN steps"
                      callback={onSkipArn}
                      // additionalStyles={{marginBottom: '30%'}}
                    /> */}
                  </>
                )
            }

          </ArnFooter>
        </Container>
      </CheckArnWrapper>
    )
  );
};


const InfoContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  background: #FFFFFF;
  padding: 2rem;
  ${'' /* flex-direction: row; */}
  ${'' /* margin-top: 150px; */}

  button {
    &:hover {
      box-shadow:0px 0px 3px 0px #6725F4 
    }
  }
`;



const SubHeading = styled.h3`
  margin: 0;
  padding: 0;
  color: #7E7E7E;
  font-weight: 400;
  ${'' /* font-size: 1rem; */}
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: 1rem;
`;


const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 3rem;
  
`;

// const Title = styled.h1.attrs({
//   className: 'primary-title'
// })`
//   margin: 0;
//   padding: 0;
//   color: #000000;
//   ${'' /* letter-spacing: 0.1rem; */}
//   align-self: flex-start;
//   margin-bottom: ${props => props.isMarginBelow ? '3rem' : '0rem'};
// `;

const SubTitleWrapper = styled.div`
  margin: 0px;
  padding: 4px 8px;
  border-radius: 2rem;
  color: rgb(28, 28, 28);
  border: 1px solid rgb(217, 217, 217);
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px
`;

const ImageContainer = styled.div`
  padding: 2rem 0rem;
  ${'' /* justify-content: center; */}
  ${'' /* align-items: center; */}
  ${'' /* width: 180px; */}
  img {
    width: 150px
  }
`;

const ImageSubText = styled.p`
  font-size: 14px;
  color: #7E7E7E;
  text-align: center;
  margin: 0rem;
  ${'' /* align-self: center; */}
  ${'' /* border-left: 1px solid #7E7E7E; */}
  padding-left: 10px;
`;

const CheckArnWrapper = styled.div`
  height: 100vh;
  width: 90%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  margin: 0 auto;
  ${'' /* background-color: #F8F4FF; */}
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25px;
    height: unset
  }
`;

const ArnContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* margin-top: 40%; */}
`;

const Container = styled.div`
  padding: 2.4rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* height: 100%; */}
  justify-content: space-between;
  background-color: #F8F4FF;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const HeaderText = styled.h1.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
`;

const ArnImage = styled.div`
  cursor: pointer;
  width: max-content;
  align-self: center;
  margin: 4rem 0rem;
  
  .arn-detected {
    height: 8rem;
  }
`;

const SubHeader = styled.span.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const Description = styled(SubHeader)`
  color: #000000;
  font-weight: 500;
  text-align: center;
`;

const ArnFooter = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 2rem
`;

CheckArn.propTypes = propTypes;

export default CheckArn;
/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton,
  Spinner
} from "screens/commons";

import {
  DigioInitialization,
  PartnerKyc,
  ClientKyc
} from "./components";
import {
  KycScreenMapping,
  KycFlowMapping
} from "types";
import IMAGES from "constants/Images";

const propTypes = {
  requestId: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  kycData: PropTypes.object.isRequired,
  handleErrorState: PropTypes.func,
  handleDigioInitialization: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  mountDigioComponent: PropTypes.bool.isRequired,
  handleLoading: PropTypes.func.isRequired,
  setMountDigioComponent: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

const DigioFlow = ({
  handleDigioInitialization,
  isLoading,
  isError,
  mountDigioComponent,
  requestId,
  updateStatus,
  tokenId,
  identifier,
  handleLoading,
  setMountDigioComponent,
  kycData,
  handleErrorState,
  handleGoBack
}) => {
  let kycComponent = <></>;

  switch (kycData.flow) {
    case 'PARTNER':
      kycComponent = <PartnerKyc
        kycData={kycData}
        handleDigioInitialization={handleDigioInitialization}
        handleGoBack={handleGoBack}
      />;
      break;

    default:
      kycComponent = <ClientKyc
        kycData={kycData}
        handleDigioInitialization={handleDigioInitialization}
        handleGoBack={handleGoBack}
      />;
      break;
  }

  const {stage,flow} = kycData;
  const showGoBackButton = (stage === KycScreenMapping.DIGILOCKER && flow === KycFlowMapping.MF_NRI) || (stage === KycScreenMapping.DIGILOCKER && flow === KycFlowMapping.PARTNER);

  return (
    <>
      {
        isError ? (
          <ErrorContainer>
            <ErrorText>Looks like something is wrong, we are looking into it, please try again after sometime.</ErrorText>
            <ActionButton
              buttonText="Retry"
              callback={updateStatus}
              additionalStyles={{
                  padding: "1.5rem"
                }}
            />
            {showGoBackButton && (
            <>
              <ActionButton
                buttonText="Process Manually"
                callback={() => handleGoBack(flow)}
                additionalStyles={{
                  background: "#FFFFFF",
                  color: "rgb(103, 37, 244)",
                  border: "2px solid rgb(103, 37, 244)",
                  padding: "1.5rem"
                }}
              />
              <div style={{
                display: "grid",
                gridAutoFlow: "column",
                gap: "0.5rem",
                marginTop: "1rem",
                color: "#7E7E7E",
                alignItems: "center"
            }}>
              <img src={IMAGES.infoIcon} alt="info-icon" title="Choose Manual option after clicking this button" />
              <div>Incase of failure or face any issues with digilocker there is always options for upload your id proofs manually. 
                &nbsp;
              </div>
              </div>
            </>
            )}
          </ErrorContainer>
        ) : (
          <>
            {
              isLoading ? (
                <>
                  <LoaderContainer>
                    <Spinner
                      color="purple"
                      message="Please do not close the window, wait while we are fetching next steps."
                    />
                  </LoaderContainer>
                  {
                    mountDigioComponent && (
                      <DigioInitialization
                        requestId={requestId}
                        identifier={identifier}
                        tokenId={tokenId}
                        handleLoading={handleLoading}
                        updateStatus={updateStatus}
                        setMountDigioComponent={setMountDigioComponent}
                        handleErrorState={handleErrorState}
                      />
                    )
                  }
                </>
              ) :
                kycComponent
            }
          </>
        )
      }
    </>
  );
};

const ErrorContainer = styled.div`
  padding: 2.4rem 2rem;
  width: 90%;
  margin: 0 auto;
  background: #F8F4FF;
  border-radius: 1rem
`;

const ErrorText = styled.p`
  margin: 5rem 0 0 0;
  padding: 0;
  color: #000000;
  font-size: 1.8rem;
  font-weight: 400;
  font-family: MavenPro;
  text-align: center;
`;

const LoaderContainer = styled.div`
  ${'' /* height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */}

  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* background: #F8F4FF; */}

  ${'' /* height: 100vh; */}
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  border-radius: 2rem;

  img {
    width: 25px;
    height: unset
  }

  @media (max-width: 768px) {
    width: 85%;
    margin: 0 auto;
  }
`;

DigioFlow.propTypes = propTypes;

export default DigioFlow;
/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import {
  createGlobalStyle
} from "styled-components";

const PartnerGlobalStyles = createGlobalStyle`{
  .primary-title {
    font-family: 'Lato-SemiBold' !important;
    font-size: 1.8rem;
    font-weight: 400;
    color: #000000;
  }
  
  .description1 {
    font-family: 'Lato' !important;
    font-size: 2rem;
    font-weight: 400;
  }

  .description2 {
    font-family: 'Lato' !important;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .button-title {
    font-family: 'Lato-SemiBold' !important;
    font-size: 1.6rem;
  }

  .caption {
    font-family: 'Lato-SemiBold' !important;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .caption2 {
    font-family: 'Lato-SemiBold' !important;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .form-input {
    font-size: 1.6rem;
    font-family: 'Lato' !important;
    font-weight: 500;
  }
}`;

export default PartnerGlobalStyles;

import styled from "styled-components";

const AnimationText = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Animation = styled.div`
  height: 6.5rem;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 2rem;
  width: 0;
  animation: uploading-progress 1s ease-out infinite;

  @keyframes uploading-progress {
    0% {
      background-color: rgba(255,255,255,1);
      width: 0;
    }
    100% {
      width: 100%;
      background-color: #6725f4ad;
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const DeleteWrapper = styled.div`
  width: 2rem;
  cursor: pointer;

  :hover {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }
`;

const DeleteIcon = styled.img`
  object-fit: "contain";
`;

const DetailWrapper = styled.div`
  margin-left: 1rem;
`;

const ExtraSpace = styled.div`
  height: 7rem;
  opacity: 0;
`;

const Text = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ImageSize = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: none;
`;

const ContentContainer = styled.div`
  padding: 2.4rem 2rem;
  margin: 0px auto;
  background: #F8F4FF;
  border-radius: 0.5rem;

  button {
    &:hover {
      box-shadow:0px 0px 3px 0px #6725F4 
    }
  }

  .dropdown-menu {
    border-radius: 0rem 0rem 0.6rem 0.6rem;
    width: calc(100% + 2px);
    margin-left: -1px;
  }
`;


const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: ${props => props.isMarginBelow ? '3rem' : '0rem'};
`;

const ImageCard = styled.div`
  height: 6.5rem;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 8px #d0d0d06e;
  display: flex;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 5rem;
  object-fit: fill;
  border-radius: 5px;
`;

const SubHeader = styled.h3`
  margin-top: 1rem;
  padding: 0;
  color: #7E7E7E;
  font-weight: 400;
  ${'' /* font-size: 1rem; */}
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: 3rem;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex: 1;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  flex-direction: column;
  span {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: .5rem;
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadImage = styled.img`
  width: 12rem;
`;

const UploadMessage = styled.p`
  margin-left: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.6rem;
  color: #7E7E7E;
  padding: 2rem 0rem;
  line-height: 2.5rem;
`;

const AttentionContainer = styled.p.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
  width: 96%;
  margin: 0.5rem auto;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  line-height: normal;

  span{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-family: Lato-SemiBold !important;
  }
`;

const AttentionWrapper = styled.div`
  background: #FFFFFF;
  padding: 1rem;
  margin: 2rem 0rem;
  border-radius: 0.5rem;
`;

const InfoImage = styled.img`
  height:18px;
  width:18px;

 
`;

const DropZoneContainer = styled(ButtonContainer)`
  border: dashed 1px #D9D9D9;
  border-radius: 5px;
  cursor: pointer;
  ${'' /* color: #193076; */}
  ${'' /* height: 30rem; */}
  ${'' /* width: 96%; */}
  margin: 0 auto;
  padding: 2rem;

  >span{
    color: #4A4A4A;
    font-weight: 500;
    font-size: .95rem;
    margin-top: .5rem;
  }
  i {
    margin-right: .5em;
  }
`;

const EachOption = styled.li`
  text-decoration: none;
  padding: 1.5rem 2rem;
  display: block;
  color: #555;
  border-bottom: 1px solid #eee;
  font-size: 1.4rem;
`;

const PasswordInput = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  color: #000000;
  width: calc(100% - 4.5rem);
  margin: 1rem 0rem 1rem 3.5rem;
  text-align: left;
  font-size: 1.25rem;

  &::placeholder {
    text-align: left;
  }

  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance:textfield;
`;

export const OptionInfoText = styled.p`
  color:#6725F4;
  margin: 0 0 18px 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-family: Lato-SemiBold !important;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const InfoTextImg = styled.img`
  height: 1.5rem;
  margin-right:5px;
`;

export const InfoTextAndImg = styled.div`
display: flex;
align-items:center;
margin-top: -2rem;

/* .pulsate-anim {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.5;
}
@keyframes pulsate {
    0% { 
        opacity: 0.5;
    }
    50% { 
        opacity: 1.0;
    }
    100% { 
        opacity: 0.5;
    }
} */

`;


const ErrorMessage = styled.p.attrs({
  className: 'error1'
})`
  margin: 2rem 0 1rem 0;
  font-weight: 500;
  text-align: center;
  font-size: 1.4rem;
  padding: 0;
  color: #EF2A00;
`;

export {
  AnimationText,
  Animation,
  ActionWrapper,
  AttentionContainer,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  ExtraSpace,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  ButtonContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer,
  EachOption,
  PasswordInput,
  ErrorMessage,
  ContentContainer,
  InfoImage,
  AttentionWrapper
};

/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import PropTypes from "prop-types";

import {
  isArnAttached,
  submitArn
} from "api";
import {
  RudderStackEvent
} from "types";
import {
  Analytics,
} from "utils";
import {
  handleApiError
} from "helpers";

import {
  ArnChoiceType,
} from "enums";

import CheckArn from "./CheckArn";
import {
  arnChoice
} from "api";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  skipPartnerStep: PropTypes.func.isRequired
};

const CheckArnContainer = ({
  kycData,
  updateStatus,
  skipPartnerStep,
  checkArn
}) => {
  const [isArnDetected, setArnDetected] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [redirectUser, setRedirectUser] = useState(false);
  const [fetchingArn, setFetchingArn] = useState(false);
  const [redirectingUser, setRedirectingUser] = useState(false);

  useEffect(() => {
    if (!kycData?.canUserSkip) {
      setRedirectUser(true); //* redirect to homepage
    }
  }, [kycData]);

  useEffect(() => {
    fetchArnStatus(handleSuccessState, handleErrorState);
  }, []);

  const skipStep = useCallback(() => {
    Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_SKIP);
    const redirectLink = window.sessionStorage.getItem("redirectTo");

    if (redirectUser && redirectLink !== "null") {
      setRedirectingUser(true);
      setTimeout(() => {
        window.location.replace(redirectLink);
      }, 1000);
    } else {
      skipPartnerStep();
    }
  }, [redirectUser]);

  const pollingArn = () => {
    const statusPoll = setInterval(async () => {
      try {
        const response = await isArnAttached();

        if (!response.data.response?.fetchingArn) {
          handleSuccessState(response);
          clearInterval(statusPoll);
          setFetchingArn(false);
        }
      } catch (error) {
        handleErrorState(error);
        clearInterval(statusPoll);
      }
    }, 5000);
  };

  const fetchArnStatus = async (handleSuccessState, handleErrorState) => {
    if(checkArn){
      try {
        setFetchingArn(true);
        const response = await isArnAttached();

        if (!response.data.response?.fetchingArn) {
          handleSuccessState(response);
          setFetchingArn(false);
        } else {
          pollingArn();
        }
      } catch (error) {
        handleErrorState(error);
      }
    }else {
      setArnDetected(false);
    }
  };

  const handleSuccessState = (response) => {
    const {data: {response: {arnDetected, arnNo, panNumber}}} = response;
    if (arnDetected) {
      Analytics.trackForRudder(RudderStackEvent.KYC_ARN_DETECTED);
    } else {
      Analytics.trackForRudder(RudderStackEvent.KYC_ARN_DETECTION_FAILED);
    }

    setArnDetected(arnDetected);
    setPartnerDetails({arnNo, panNumber});
  };

  const handleErrorState = (error) => {
    Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_FAILED);
    handleApiError(error);
  };

  const onSubmit = async () => {
    try {
      setIsloading(true);
      const res = await submitArn();

      if (res.status === 200 || res.status === 201) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsloading(false);
    }
  };

  const onSkipARN = async () => {
    try {
      setIsloading(true);
      const value = ArnChoiceType.Skip;
      Analytics.trackForRudder(RudderStackEvent.KYC_ARN_SKIP);

      const res = await arnChoice({"selection": value});
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsloading(false);
    }
  };

  const onUploadARN = async () => {
    try {
      setIsloading(true);
      const value = ArnChoiceType.UploadArn;
      Analytics.trackForRudder(RudderStackEvent.KYC_ARN_UPLOAD);

      const res = await arnChoice({"selection": value});
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <CheckArn
      onSubmit={onSubmit}
      // isArnDetected={isArnDetected}
      isArnDetected={isArnDetected}
      partnerDetails={partnerDetails}
      isLoading={isLoading}
      redirectingUser={redirectingUser}
      fetchingArn={fetchingArn}
      onUploadArn={onUploadARN}
      onSkipArn={onSkipARN}
    />
  );
};

CheckArnContainer.propTypes = propTypes;

export default CheckArnContainer;

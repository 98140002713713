/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import styled from "styled-components";

const PartnerBankDetailsWrapper = styled.div`
  ${'' /* height: 100vh; */}
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  height: 80%;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  ${'' /* letter-spacing: 0.1rem; */}
  margin-bottom: 1rem;
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  
  button{
    &:hover {
      box-shadow:0px 0px 3px 0px #6725F4 
    }
  }
`;

const SubHeader = styled.span.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
  cursor: pointer;
`;

const Header = styled.div`
  display: grid;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const SubHeading = styled.h3`
  margin: 0;
  padding: 0;
  color: #7E7E7E;
  font-weight: 400;
  ${'' /* font-size: 1rem; */}
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
`;


const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  ${'' /* margin-bottom: 3rem; */}
  align-items: center;
`;


const SubTitleWrapper = styled.div`
  margin: 0px;
  padding: 4px 8px;
  border-radius: 2rem;
  color: rgb(28, 28, 28);
  border: 1px solid rgb(217, 217, 217);
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px
`;

export {
  PartnerBankDetailsWrapper,
  ExtraSpace,
  Container,
  InputsContainer,
  Title,
  SubHeader,
  Header,
  LoaderContainer,
  SubHeading,
TitleWrapper,
SubTitleWrapper,
SubTitle
};
